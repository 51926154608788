.ant-radio-wrapper{
  @apply text-white;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{
  @apply bg-primary-500 border-primary-500;
}

.ant-radio-wrapper .ant-radio-inner{
  @apply bg-neutral-900 border-neutral-600;
}

.ant-radio-wrapper:hover .ant-radio-inner{
  @apply border-neutral-500;
}


.ant-radio-wrapper{
  margin-inline-end:40px;
}