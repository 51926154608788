.iconShadow {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
}

.buttonShadow {
  box-shadow: 0px 0px 8px 8px rgba(126, 196, 101, 0.15);
}

.luckymintBannerShadow {
  box-shadow: 0px 0px 8px -4px rgba(0, 0, 0, 0.12),
    0px 4px 16px -1px rgba(0, 0, 0, 0.08) !important;
}
