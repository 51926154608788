@tailwind base;
@tailwind components;
@tailwind utilities;

@import './ant-modal.css';
@import './ant-radio.css';
@import './ant-pagination.css';
@import './ant-dropdown.css';
@import './animation.css';
@import './gradient.css';
@import './shadow.css';

html,
body {
  background: #14171a;
  font-family: 'Rubik';
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  background-color: rgba(255, 255, 255, 0.45);
}

div[tabindex]:focus {
  outline: none;
}

.urlDescription {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.filter {
  box-shadow: 4px 12px 32px rgba(70, 87, 147, 0.08);
  border-radius: 16px;
  border: 1px solid #39424c;
  background: #1a1e23;
  /* padding: 12px; */
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

input:focus-visible {
  width: 100% !important;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

.ant-modal-content,
.ant-modal-header {
  background-color: #1a1e23 !important;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.skeleton {
  background: linear-gradient(
    90deg,
    rgba(33, 38, 44, 0.6) 25%,
    rgba(255, 255, 255, 0.2) 37%,
    rgba(33, 38, 44, 0.6) 63%
  );
  background-size: 400% 100%;
  animation-name: skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  border-radius: 8px;
}
wcm-modal {
  position: fixed;
  z-index: 999;
}
