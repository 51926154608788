.progressBg {
  background: linear-gradient(90deg, #b6f600 0%, #ff6f00 100%);
}

.freeBg {
  background: linear-gradient(269.81deg, #52dd15 -0.31%, #ffea29 99.82%);
}

.launchBg {
  border-image: linear-gradient(270deg, #89ee4b 0%, #fff501 100%) 1;
  background: linear-gradient(270deg, #89ee4b 0%, #fff501 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.launchBg2 {
  background: linear-gradient(270deg, #89ee4b 0%, #fff501 100%);
}

.automateBg {
  background: linear-gradient(267.21deg, #52dd15 0%, #ffea29 95.35%);
}

.calender-project-card-bg {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    linear-gradient(90deg, #b6f600 0%, #ff6f00 100%);
}

.bannerMask {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 52%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.projectCardMask {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.luckymintBannerGradient {
  background-image: linear-gradient(#ffe96a, #f3cf00);
  background-clip: text;
  -webkit-background-clip: text; /* 兼容性支持，适用于一些旧版浏览器 */
  color: transparent;
}
