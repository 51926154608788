.ant-modal-mask {
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.ant-modal-root .ant-modal-wrap {
  @apply h-full w-full;
  @apply flex items-center justify-center;
}

.ant-modal {
  @apply top-0;
}

.ant-modal-content {
  background-color: #1a1e23 !important;
  padding: 32px 36px !important;
  @apply max-h-[calc(100vh-80px)] overflow-y-scroll;
}

.ant-modal-header {
  margin-bottom: 24px !important;
  background-color: transparent !important;
}

.ant-modal .ant-modal-title {
  @apply text-2xl font-bold italic text-neutral-100 text-opacity-100;
}

.ant-modal-close {
  width: 24px !important;
  height: 24px !important;
  top: 28px !important;
  right: 36px !important;
  inset-inline-end: 32 !important;
}

:where(.css-dev-only-do-not-override-nrlx8r).ant-modal .ant-modal-header {
  background: transparent;
}

.ant-modal .ant-modal-close:hover {
  background-color: #1a1e23 !important;
}

.ant-modal .ant-modal-content {
  @apply rounded-2xl;
}

.ant-modal .ant-modal-footer {
  @apply mt-7;
}
