.ant-pagination {
  margin: 0;
  @apply bg-neutral-1000;
}

.ant-pagination {
  padding: 16px 24px;
  @apply rounded-b-2xl border border-neutral-600;
  @apply flex items-center justify-between;
}

.ant-pagination .ant-pagination-item a {
  @apply pt-[2px] text-sm font-medium text-white;
}

.ant-pagination-total-text {
  text-align: left;
  flex: 1;
  font-weight: 500;
  font-size: 14px;
  color: #4e5a6e;
}

.ant-pagination-item {
  background-color: transparent !important;
  @apply text-white;
}

.ant-pagination-prev > a:hover {
  @apply text-neutral-200;
}

.ant-pagination-next > a:hover {
  @apply text-neutral-200;
}

.ant-pagination-item > a:hover {
  @apply text-neutral-200;
}

.ant-pagination-disabled > a {
  @apply text-neutral-600;
}

.ant-pagination-disabled > a:hover {
  @apply text-neutral-600;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-jump-prev {
  margin-inline-end: 8px;
}

.ant-pagination .ant-pagination-item {
  margin-inline-end: 0;
}

.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-next {
  margin-inline-start: 8px;
}

.ant-pagination .ant-pagination-item-active {
  border: none;
  background-color: transparent;
  line-height: 32px;
}

.ant-pagination .ant-pagination-item-active a {
  @apply pt-[2px] text-sm font-medium text-primary-500;
}

.ant-pagination .ant-pagination-item-active a:hover {
  @apply pt-[2px] text-sm font-medium text-neutral-200;
}
