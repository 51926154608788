@keyframes breath {
  0% {
    transform: scale(1);
  } /* 开始时的缩放比例 */
  50% {
    transform: scale(1.1);
  } /* 达到最大缩放比例的时间点 */
  100% {
    transform: scale(1);
  } /* 动画结束时的缩放比例 */
}

.breath-animation {
  animation-name: breath;
  animation-duration: 2s; /* 动画持续时间 */
  animation-timing-function: ease-in-out; /* 缓动函数，控制动画的加速度 */
  animation-iteration-count: infinite; /* 无限循环播放动画 */
}

@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0px, -3);
  }
  50% {
    transform: translate(0px, -5px);
  }
  75% {
    transform: translate(0, -10px);
  }
}

.shaking-image {
  animation: shake 2s linear infinite;
}
